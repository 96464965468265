<script setup>
// Properties
const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  required: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    default: '',
  },
})

// Events
const emit = defineEmits(['custom-blur', 'custom-change', 'update:modelValue'])

// Computed Values
const current = computed(() => {
  const options = props.options
  const result = options.find((option) => option.value === props.modelValue)
  return result || null
})

const display = computed(() => {
  return current.value ? current.value.label : props.label
})

// Handlers
function handleBlur() {
  emit('custom-blur')
}

function handleChange(e) {
  emit('update:modelValue', e.target.value)
  emit('custom-change')
}
</script>

<template>
  <div :class="['input-select', { 'input-select--placeholder': !modelValue }]">
    <select
      class="input-select__select"
      @blur="handleBlur"
      @change="handleChange"
    >
      <option :selected="!modelValue" disabled>{{ label }}</option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>

    <div class="input-select__label">
      {{ display }}
    </div>
  </div>
</template>

<style lang="scss">
@use 'sass:math';
@import '~/styles/utils/variables';
@import '~/styles/vendors/sass-mq/mq';

.input-select {
  position: relative;
  padding: 0;

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Euclid Galactic', sans-serif;

    letter-spacing: -0.01em;
    font-size: 15px;
    line-height: 22px;
    font-weight: $font-weight-2G;
    text-align: center;
    color: rgba($brand-white, 0.9);
    background-color: #200929;
    border: solid 1.5px #1d083a;
    border-radius: 32px;
    box-shadow: none;
    // padding-top: 21px;
    // padding-bottom: 20px;
    width: 100%;
    height: 60px;
    position: relative;
    outline: none;

    background: url('assets/images/icons/chevron-down--purple.svg'), #200929;
    background-origin: border-box;
    background-size: 12px, auto;
    background-repeat: no-repeat !important;
    background-position: calc(100% - 24px) center, center;
    padding-inline-start: 0px;
    appearance: none;
    cursor: pointer;

    @include mq($from: 'l') using($from) {
      line-height: math.div(16, 11);
      // padding-top: 22px;
      // padding-bottom: 21px;
    }
  }

  &__select {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    right: 0;
    appearance: none;

    width: 100%;
    opacity: 0;
    z-index: 1;

    &:active + .input-select__label,
    &:focus + .input-select__label {
      border-color: transparent;
      background-image: url('assets/images/icons/chevron-down--purple.svg'),
        radial-gradient(
          200px 250px at 50% 50%,
          rgba(#c80afb, 0.1) 0%,
          rgba(200, 10, 251, 0.063) 31.77%,
          rgba(200, 10, 251, 0.036) 60.94%,
          rgba(200, 10, 251, 0) 99.94%
        ),
        linear-gradient(#200929, #200929),
        linear-gradient(103.2deg, #d90afb -13.13%, #31afd4 93.9%);
      background-origin: border-box;
      background-clip: padding-box, padding-box, padding-box, border-box;
      background-size: 12px, auto, auto, auto;
      background-position: calc(100% - 24px) center, center, center, center;
    }
  }

  &--placeholder {
    .input-select__label {
      color: #936dcd;
      font-size: 11px;
      font-weight: $font-weight-4G;
      line-height: math.div(12, 11);
      letter-spacing: 0.24em;
      text-transform: uppercase;
    }
  }

  &.has-error {
    .input-select__label {
      border-color: transparent;
      background-image: url('assets/images/icons/chevron-down--purple.svg'),
        radial-gradient(
          130px 270px at 50% 50%,
          rgba(#c80afb, 0.05) 0%,
          rgba(200, 10, 251, 0.0315) 31.77%,
          rgba(200, 10, 251, 0.018) 60.94%,
          rgba(200, 10, 251, 0) 99.94%
        ),
        linear-gradient(#200929, #200929), linear-gradient(#f2287d, #f2287d);
      background-origin: border-box;
      background-clip: padding-box, padding-box, padding-box, border-box;
      background-size: 12px, auto, auto, auto;
      background-position: calc(100% - 24px) center, center, center, center;
    }
  }
}
</style>
